import React from 'react';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';

export default function Terms() {
    return (
        <div className="mkt" style={{backgroundColor:'black'}}>
            <Header  />
            <section >
                <Container className="pt-10" style={{color:'white'}}>
                    <h1><span style={{fontWeight: 400}}>T&eacute;rminos y condiciones</span></h1>
                    <p><span style={{fontWeight: 400}}>Estas cl&aacute;usulas le brindar&aacute;n la informaci&oacute;n que usted requiere saber acerca de nuestro m&eacute;todo de trabajo, y servir&aacute;n como una gu&iacute;a en la resoluci&oacute;n de dudas y/o conflictos que se pudieran presentar.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Convenio entre cliente y 4think</span></p>
                    <p><span style={{fontWeight: 400}}>El presente documento es un acuerdo entre usted como cliente y&nbsp; 4think como su proveedor de servicios de dise&ntilde;o web, Dise&ntilde;o Gr&aacute;fico, marketing y dem&aacute;s servicios. Al contratar los servicios de&nbsp; 4think, usted debe haber le&iacute;do y aceptado los t&eacute;rminos y condiciones redactados en este convenio, ya que en &eacute;ste se enumeran los derechos y obligaciones para ambas partes.</span></p>
                    <p>&nbsp;</p>
                    <ol>
                        <li><span style={{fontWeight: 400}}> Cotizaci&oacute;n y propuesta de trabajo</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>Nuestros precios est&aacute;n sujetos a cambio sin previo aviso. En el caso de que el cliente no haya contratado el proyecto y los precios hayan sido modificados, se le har&aacute; una nueva cotizaci&oacute;n con los nuevos precios. En la modificaci&oacute;n de un paquete creado a las necesidades de la empresa, se har&aacute; una nueva cotizaci&oacute;n. Cada servicio por separado del paquete genera otro costo. El precio se modificar&aacute; por cambios del cliente, se notificar&aacute; antes de producci&oacute;n.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>(1a.) Pagar IVA</span></p>
                    <p><span style={{fontWeight: 400}}>Nuestras cotizaciones tienen una vigencia de 15 d&iacute;as h&aacute;biles e incluyen el pago de IVA si requiere factura</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>(2b.) Saldo a favor</span></p>
                    <p><span style={{fontWeight: 400}}>En caso de tener un saldo favorable, este saldo se deber&aacute; utilizar en horas. No aplicar&aacute;n descuentos.</span></p>
                    <p>&nbsp;</p>
                    <ol start="2">
                        <li><span style={{fontWeight: 400}}> Desarrollo de proyecto</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>Anticipo</span></p>
                    <p><span style={{fontWeight: 400}}>Antes de comenzar a trabajar cualquier proyecto, se requiere un anticipo por nuestros servicios.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Si son proyectos con el costo de 50,000 pesos, se requiere el 30% de anticipo, 30% despu&eacute;s de la etapa de dise&ntilde;o, y el resto contra entrega del sitio.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Proceso de trabajo</span></p>
                    <p><span style={{fontWeight: 400}}>El desarrollo se divide en cinco etapas:</span></p>
                    <p>&nbsp;</p>
                    <ol>
                        <li><span style={{fontWeight: 400}}> Recepci&oacute;n de material: El cliente env&iacute;a el material necesario para iniciar el proyecto, en caso del logotipo o nombre, el material es el cuestionario para logos o nombre y de sitios web, la informaci&oacute;n para la creaci&oacute;n del Sitio.</span></li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="2">
                        <li><span style={{fontWeight: 400}}> Etapa de dise&ntilde;o: Creaci&oacute;n del material gr&aacute;fico o web en sitios web se enviara en imagen la pantalla de inicio y una p&aacute;gina interna.</span></li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="3">
                        <li><span style={{fontWeight: 400}}> Retroalimentaci&oacute;n Se env&iacute;a el proyecto al cliente y se obtienen sus comentarios. El cliente cuenta con 3 cambios por dise&ntilde;o y no son acumulables.</span></li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="4">
                        <li><span style={{fontWeight: 400}}> Etapa de depuraci&oacute;n del proyecto se realizan los cambios o ajustes se&ntilde;alados por el cliente, durante esta etapa el cliente puede solicitar las rondas que fueron expl&iacute;citas dentro la cotizaci&oacute;n de cambios/modificaciones. Revisiones adicionales tendr&aacute;n un costo adicional.</span></li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>5 Env&iacute;o de materiales o puesta en l&iacute;nea del servicio web.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Solo para sitio web</span></p>
                    <p><span style={{fontWeight: 400}}>&nbsp;4think le presentar&aacute; al cliente la propuesta de dise&ntilde;o (p&aacute;gina de inicio y p&aacute;gina interna) para su aprobaci&oacute;n y/o modificaci&oacute;n. Al ser aprobado por el cliente, se comenzar&aacute; a trabajar en el desarrollo (programaci&oacute;n) del proyecto. Cualquier modificaci&oacute;n adicional que se solicite sobre el dise&ntilde;o previamente aprobado generar&aacute; un cargo extra.&nbsp; 4think mantendr&aacute; al cliente informado.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Al terminar el desarrollo del proyecto, se mostrar&aacute; la versi&oacute;n BETA al cliente, quien deber&aacute; realizar una revisi&oacute;n final del sitio para asegurarse de que todos los datos est&aacute;n correctos, y asimismo podr&aacute; solicitar las modificaciones mencionadas en la cotizaci&oacute;n para ajustar los &uacute;ltimos detalles.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Cualquier anexaci&oacute;n de informaci&oacute;n adicional al sitio web o modificaciones a los formatos previamente aprobados, ser&aacute;n cotizados aparte. En ocasiones ser&aacute; necesario finiqutar el 50% restante del proyecto antes de llevar a cabo las nuevas modificaciones.&nbsp; 4think se compromete a mantener informado al cliente y a determinar cu&aacute;l es el mejor momento para llevar a cabo dichas modificaciones, tomando en cuenta las horas de trabajo y la dificultad de las mismas.</span></p>
                    <p>&nbsp;</p>
                    <ol start="3">
                        <li><span style={{fontWeight: 400}}> Pago final y entrega del proyecto</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>Cuando el proyecto est&eacute; terminado, se le solicitar&aacute; al cliente el pago final del 50%, el cual deber&aacute; ser cubierto a m&aacute;s tardar durante los siguientes 10 d&iacute;as h&aacute;biles. Si el cliente se demora m&aacute;s del tiempo estipulado para finiquitar el proyecto, se le cobrar&aacute; una multa del 2% sobre el pago final por d&iacute;a de atraso.&nbsp; 4think est&aacute; exento de entregar el proyecto al cliente mientras no se haya cubierto la totalidad del pago.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>(3a.) Garant&iacute;a</span></p>
                    <p><span style={{fontWeight: 400}}>La etapa de debuggeo tiene una garant&iacute;a solo 30 d&iacute;as y aplica solo para errores t&eacute;cnicos y no para cambios.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>(3b.) Archivos editables</span></p>
                    <p><span style={{fontWeight: 400}}>Los archivos editables no se entregar&aacute;n al cliente. Los archivos que se encuentran en el FTP son los &uacute;nicos que se entregan sin haber tenido un acuerdo al principio del proyecto.</span></p>
                    <p>&nbsp;</p>
                    <ol start="4">
                        <li><span style={{fontWeight: 400}}> Cargos Extra</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>Con el fin de no afectar el presupuesto del cliente, cualquier cargo extra que pudiera generarse durante el proyecto, SIEMPRE SER&Aacute; NOTIFICADO AL CLIENTE ANTES DE LLEVARLO A CABO. El cliente decidir&aacute; dar su aprobaci&oacute;n para seguir adelante con las modificaciones, o descartarlas y continuar con lo previamente cotizado. Los cargos extras pueden ser calculados por horas de trabajo tomando en cuenta el grado de dificultad de las modificaciones, o por objeto de acuerdo a nuestra lista de precios.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>(4a.) Respaldo de sitio</span></p>
                    <p><span style={{fontWeight: 400}}>Si el cliente tiene un sitio actual en su dominio que desea respaldar, se generar&aacute; un costo adicional tomando en cuenta la tarifa por hora. (Revisa tarifa actual)</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>(4b.) Dominio u hospedaje exterior</span></p>
                    <p><span style={{fontWeight: 400}}>&nbsp;4think no ser&aacute; responsable de problemas causados por un hospedaje o dominio exterior. Tiempo gastado en configurar o averiguar problemas, hablando con su proveedor, transfiriendo etc. ser&aacute;n cobrados como mantenimiento.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>(4c.) Cuentas de correo</span></p>
                    <p><span style={{fontWeight: 400}}>&nbsp;4think no migrara cuentas de correo asociadas con su dominio al cambiar el hospedaje.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>(4d.) Soporte t&eacute;cnico</span></p>
                    <p><span style={{fontWeight: 400}}>Soporte t&eacute;cnico aparte de lo que est&aacute; cotizado tiene un costo adicional y se podr&aacute; cotizar o cobrar por hora.</span></p>
                    <p>&nbsp;</p>
                    <ol start="5">
                        <li><span style={{fontWeight: 400}}> Cancelaci&oacute;n del proyecto y devoluciones</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>Cualquier cancelaci&oacute;n del servicio por parte del cliente queda exenta de devoluci&oacute;n de ninguna cantidad de dinero por parte de&nbsp; 4think.&nbsp; 4think tiene la libertad de cancelar o dar de baja el proyecto en caso de que el cliente, durante el desarrollo del proyecto, no cumpla con sus obligaciones en este convenio.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Los siguientes son casos de cancelaci&oacute;n de proyecto:</span></p>
                    <p><span style={{fontWeight: 400}}>Proyecto abandonado: Despu&eacute;s de 20 d&iacute;as h&aacute;biles de haber recibido el primer dep&oacute;sito el proyecto se cancelar&aacute;. Si el cliente desea retomar el proyecto, se re-cotizar&aacute; por completo.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Falta de material (contenido, fotos, informaci&oacute;n clave): Si el cliente no entrega el material necesario para llevar acabo el proyecto despu&eacute;s de 20 d&iacute;as h&aacute;biles se cancelar&aacute; el proyecto o se re-cotizar&aacute;.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Proyecto en pausa: Si por alguna raz&oacute;n de parte del cliente el proyecto se tiene que poner en pausa, se tendr&aacute; que revisar la cotizaci&oacute;n y llegar a un nuevo acuerdo.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Falta de respuesta del cliente: S&iacute; en un plazo de 20 d&iacute;as h&aacute;biles el proyecto queda pausado por falta de respuesta o inter&eacute;s del cliente, se considerar&aacute; como proyecto abandonado.</span></p>
                    <p>&nbsp;</p>
                    <ol start="6">
                        <li><span style={{fontWeight: 400}}> Promociones</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>Las promociones ser&aacute;n modificadas o sustituidas despu&eacute;s de alg&uacute;n tiempo. En dado caso de que la promoci&oacute;n o descuento ya no se encuentre en la p&aacute;gina,&nbsp; 4think no est&aacute; obligado a hacer v&aacute;lida dicha promoci&oacute;n.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Algunas promociones son exclusivas para los clientes ya existentes de&nbsp; 4think para premiar su lealtad y preferencia, y por tal motivo no se podr&aacute;n aplicar a un nuevo cliente, bajo ninguna circunstancia.</span></p>
                    <p>&nbsp;</p>
                    <ol start="7">
                        <li><span style={{fontWeight: 400}}> Modificaciones a este convenio</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>Los actuales t&eacute;rminos y condiciones est&aacute;n sujetos a cambios sin previo aviso por parte de&nbsp; 4think.</span></p>
                    <p>&nbsp;</p>
                    <ol start="8">
                        <li><span style={{fontWeight: 400}}> Sub-contrataci&oacute;n</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>&nbsp;4think tiene el derecho de sub-contratar para asegurar que los proyectos se desarrollen adecuadamente sin previo aviso al cliente.</span></p>
                    <p>&nbsp;</p>
                    <ol start="9">
                        <li><span style={{fontWeight: 400}}> Material prohibido:</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>&nbsp;4think tiene el derecho de rechazar lo siguiente:</span></p>
                    <p>&nbsp;</p>
                    <ol>
                        <li><span style={{fontWeight: 400}}> Cualquier medio de comunicaci&oacute;n que es ilegal o inapropiado;</span></li>
                        <li><span style={{fontWeight: 400}}> Cualquier medio que contenga un virus o programa hostil;</span></li>
                        <li><span style={{fontWeight: 400}}> Cualquier medio de comunicaci&oacute;n que constituye acoso, racismo, violencia, obscenidad, spam o intenci&oacute;n de hacer da&ntilde;o;</span></li>
                        <li><span style={{fontWeight: 400}}> Cualquier medio de comunicaci&oacute;n que constituye un delito penal, viola la privacidad o derechos de autor;</span></li>
                        <li><span style={{fontWeight: 400}}> Cualquier otro medio de cuestionables estar&aacute; debajo la discreci&oacute;n de&nbsp; 4think.</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>Muchas gracias por ser nuestro cliente.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Cualquier duda o pregunta acerca de este proyecto contactanos.</span></p>
                </Container>
            </section>
            <Footer  white bgBlack  />
        </div>
    )
}
