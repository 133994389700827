import React, {useState} from 'react'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 440,
    width: 260,
    borderRadius: 25
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function PaperServices(props) {
  const classes = useStyles();
  const { title, subtitle, buttonTitle, image, link, anim } = props;
  const [animBg, setAnimBg] = useState(false);

  const toggleHover = (status) =>{
    setAnimBg(status);
  }
  return (
    <Paper onMouseEnter={() => toggleHover (true)} onMouseLeave={() => toggleHover (false)} className={[classes.paper, "wow animate__animated animate__backInDown spacejetAnim"]} style={{ backgroundImage: animBg ? anim : '' }} >
      <img className="spacejet" src={image} style={{opacity: !animBg ? 1 : 0}}></img>
      <h3 className="title-paper wow animate__animated animate__bounceInLeft" style={{ height: 60 }}>{title}</h3>
      <p style={{ height: 80, padding:20 }}>{subtitle}</p>
      <Button className="m-2 buttton-services wow animate__animated animate__bounceInUp" color="primary">
        <Link className="link-white" to={link}>{buttonTitle}</Link>
      </Button>
    </Paper>
  )
}
