import React from 'react';
import Container from '@material-ui/core/Container';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Grid from '@material-ui/core/Grid';
import CarouselMkt from '../../components/CarouselMkt';
import { Link } from 'react-router-dom';

export default function Tecnology() {
    return (
        <div >
            <Header />
            <section style={{ backgroundColor: 'black' }} >
                <CarouselMkt tecno />
            </section>
            <section style={{ backgroundColor: '#1C1C1C' }} >
                <Container >
                    <div className="flex-grow pt-5 pb-5">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Grid item xs={10} className="pt-10 pl-5">
                                    <h1 className="title-service text-white">Realidad</h1>
                                    <h1 className="subtitle-service text-white">aumentada</h1>
                                    <p className="text-service text-white   pt-5">
                                        Conoce las posibilidades de interacción que  hacen sobresalir en el mundo digital. La realidad Aumentada es un ofrece experiencias interactivas al usuario a partir de la combinación entre la dimensión virtual y la física.
                                    </p>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} >
                                <img width="628" className="pl-5" src="/img/services/tecno/ar.png" />
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>
            <section className="bg-tecno-1" >
                <Container >
                    <div className="flex-grow pt-15 pb-15">
                        <Grid container spacing={3}>
                            <Grid item xs={6} >
                            </Grid>
                            <Grid item xs={6}>
                                <Grid item xs={12} className="pt-10 pl-15">
                                    <h1 className="title-service text-white">Realidad</h1>
                                    <h1 className="subtitle-service text-white">virtual</h1>
                                    <p className="text-service pt-2 text-white">
                                        No solo es para entretenimientos, también puede emplearse en medicina y educación. Aunque la teletransportación aún no es posible, gracias a ella podemos decir que la realidad virtual es un paso gigante en ese objetivo.
                                    </p>
                                </Grid>
                            </Grid>

                        </Grid>
                    </div>
                </Container>
            </section>
            <section className="bg-tecno-footer" >
                <Container >
                    <div className="flex-grow pt-15" style={{ height: 450 }}>
                        <div class="subtitle-footer-btn">
                            <Link to="/contact">
                                <h1 className="title-general text-white text-left wow animate__animated animate__bounceInUp">Innovemos juntos</h1>
                                <span className="subtitle-footer text-white text-left">{'Contáctanos >'}</span>
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer bgBlack white />
        </div>
    );
}