import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import blue from '@material-ui/core/colors/blue'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames';
import '../../css/slide.css';
import { AutoRotatingCarousel } from 'material-auto-rotating-carousel';
import Header from '../../common/Header';
import Social from '../../common/Socials';
import PlayArrow from '@material-ui/icons/PlayArrow';
import QuitIcon from '@material-ui/icons/Cancel';
import VolumenIcon from '@material-ui/icons/VolumeUpRounded';
import VolumenMuteIcon from '@material-ui/icons/VolumeMuteRounded';
import Modal from '@material-ui/core/Modal';
import ModalGift from '../../components/ModalGift'


const styles = {
    root: {
        backgroundColor: blue[500],
        height: '100%'
    },
    rootMobileLandscape: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    media: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > *': {
            maxHeight: '100%'
        }
    },
    mediaMobile: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    mediaMobileLandscape: {},
    mediaBackground: {
        backgroundColor: blue[700],
        height: '100%',
        textAlign: 'center'
    },
    mediaBackgroundMobile: {
        height: '100%'
    },
    mediaBackgroundMobileLandscape: {
        height: '100%',
        flex: '1 1',
        alignSelf: 'stretch'
    },
    text: {
        textAlign: 'center',
        maxWidth: '80%',
        margin: '0 auto',
        paddingTop: 32,
        zIndex: 99
    },
    textMobile: {
        paddingTop: 30,
        '& $title': {
            marginBottom: 8
        }
    },
    textMobileLandscape: {
        minWidth: 300,
        maxWidth: 'calc(50% - 48px)',
        padding: '24px 24px 128px',
        flex: '0 1',
        alignSelf: 'center',
        textAlign: 'left',
        margin: 0
    },
    title: {
        font: 'Regular 137px/222px var(--unnamed-font-family-brume)',
        color: 'var(--unnamed-color-ffffff)',
        textAlign: 'left',
        font: 'Regular 137px/222px Brume',
        letterSpacing: 0,
        color: '#FFFFFF',
        opacity: 1
    },
    subtitle: {
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '18px',
        margin: 0,
        color: '#fff'
    }
}

function Index(props) {
    const {
        classes,
        media,
        mediaBackgroundStyle,
        subtitle,
        title,
        mobile,
        landscape,
        image,
        video,
        ...other
    } = props

    const mobileLandscape = mobile && landscape
    const myVideo = useRef(null);
    const [open, setOpen] = useState(true);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const playVideo = () => {
        setIsMute(false);
        myVideo.current.play();
        setShowInfo(false);
    }
    const [showInfo, setShowInfo] = useState(true);
    const [isMute, setIsMute] = useState(true);


    const stopVideo = () => {
        setIsMute(!isMute);
        setShowInfo(true);
    }

    const muteVideo = () => {
        setIsMute(!isMute);
    }

    return (
        <div>
            <Header />
            <AutoRotatingCarousel
                open={showInfo}
                onStart={true}
                mobile
                autoplay={false}
                style={{ position: 'absolute', overflow: 'hidden' }}
            >
                <div
                    className={classNames(classes.root, {
                        [classes.rootMobile]: mobile,
                        [classes.rootMobileLandscape]: mobileLandscape
                    })}
                    {...other}
                >

                    <div
                        className={classNames(classes.mediaBackground, {
                            [classes.mediaBackgroundMobile]: mobile,
                            [classes.mediaBackgroundMobileLandscape]: mobileLandscape
                        })}
                    >
                        <div className={classNames(classes.media, {
                            [classes.mediaMobile]: mobile,
                            [classes.mediaMobileLandscape]: mobileLandscape
                        })}>
                            <div
                                className={classNames(classes.text, {
                                    [classes.textMobile]: mobile,
                                    [classes.textMobileLandscape]: mobileLandscape
                                })}
                            >
                                <p className="title" >
                                    <span>Digital<br />
                                        <button onClick={playVideo} class="more-btn">
                                            <PlayArrow className="playIcon" />
                                        </button>
                                        Solutions
                                    </span>
                                </p>
                                <p className="subtitle"><p class="subtitle-home">Sómos estrategas que nacimos en el mundo digital. <br></br>  Somos un equipo con ideas, creamos soluciones y damos resultados</p> </p>
                            </div>
                            {image}
                        </div>

                    </div>
                </div>
            </AutoRotatingCarousel>
            {
                !showInfo && (
                    <div className="control-buttons">
                        <QuitIcon className="iconSocialWhite button-home" onClick={stopVideo} />
                        {
                            !isMute ?
                                (
                                    <VolumenIcon className="iconSocialWhite button-home" onClick={muteVideo} />
                                ) :
                                (
                                    <VolumenMuteIcon className="iconSocialWhite button-home" onClick={muteVideo} />
                                )
                        }

                    </div>
                )
            }
            <video ref={myVideo} className="homeVideo" loop muted={isMute} autoPlay>
                <source src="video/home.mp4" type="video/mp4" />
            </video>
            <Social align="right" color="White" />
        </div>
    )
}

Index.propTypes = {
    /**
     * Useful to extend the style applied to components.
     */
    classes: PropTypes.object.isRequired,
    /**
     * Object to display in the upper half.
     */
    media: PropTypes.node.isRequired,

    video: PropTypes.string.isRequired,
    /**
     * Override the inline-styles of the media container.
     */
    mediaBackgroundStyle: PropTypes.object,
    /**
     * Subtitle of the slide.
     */
    subtitle: PropTypes.string.isRequired,
    /**
     * Title of the slide.
     */
    title: PropTypes.any.isRequired,
    /**
     * If `true`, the screen width and height is filled.
     * @ignore
     */
    mobile: PropTypes.bool,
    /**
     * If `true`, slide will adjust content for wide mobile screens.
     * @ignore
     */
    landscape: PropTypes.bool
}

export default withStyles(styles)(Index)