import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import InstagramIcon from '@material-ui/icons/Instagram';
import { ReactComponent as Behance } from '../icons/behance.svg';
import { ReactComponent as fb } from '../icons/fb.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import PinterestIcon from '@material-ui/icons/Pinterest';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
export default function Socials(props) {
    return (
        <div className={props.align}>
            <a target="_blank" href="https://www.instagram.com/4think.digital/">
                <IconButton aria-label="Instagram">
                    <InstagramIcon className={`iconSocial${props.color}`} />
                </IconButton></a>
            <a target="_blank" href="https://www.facebook.com/4thinkdigital/">
                <IconButton aria-label="FaceBook">
                    <SvgIcon component={fb} color="action" className={`iconSocial${props.color} ${props.color}`} viewBox="0 12 32 8" />
                </IconButton>
            </a>
            <a target="_blank" href="https://twitter.com/4thinkdigital">
                <IconButton aria-label="Twitter">
                    <TwitterIcon className={`iconSocial${props.color}`} />
                </IconButton></a>
            <a target="_blank" href="https://www.youtube.com/channel/UCva5RN-v2rFuwJgbhjT2_EQ?view_as=subscriber">
                <IconButton aria-label="Youtube">
                    <YouTubeIcon className={`iconSocial${props.color}`} />
                </IconButton>
            </a>
            <a target="_blank" href="https://www.behance.net/4think">
                <IconButton aria-label="Behance">
                    <SvgIcon component={Behance} color="action" className={`iconSocial${props.color} ${props.color}`} viewBox="0 12 32 8" />
                </IconButton></a>
            <a target="_blank" href="https://www.pinterest.com.mx/4think/">
                <IconButton aria-label="Pinterest">
                    <PinterestIcon className={`iconSocial${props.color}`} />
                </IconButton></a>
        </div>
    )
}
