import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Socials from '../common/Socials'
import InputText from '../components/InputText';
import ContactIcons from '../components/ContactIcons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '100%',
        height: '100%',
    },
}));

export default function Contact() {
    const classes = useStyles();

    return (
        <div className="bg-contact ">
            <Header />
            <section className="fxt-content-wrap-layout4" >
                <Container>
                    <Grid container spacing={12}>
                        <Grid item xs={6} className="text-center">
                            <div className="pt-15">
                                <h4 className="title-contact white" >
                                    Pensemos <br/> juntos
                                </h4>
                                <div className="contact-text white mt-5">
                                    <ContactIcons />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} className="text-center">
                            <Card className="contact-box">
                                <h4 className="text-center detail-contact " >
                                    Queremos ser parte <br/> de tu proyecto
                                </h4>
                                <InputText fullWidth label="Nombre" />
                                <InputText fullWidth label="Correo electrónico" />
                                <InputText fullWidth label="¿Cómo podemos ayudarte?"  multiline rows={6} />
                                <Button className="m-2 button-primary" variant="outlined" color="primary">
                                    Envíar
                                </Button>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <Footer white />
        </div>
    );
}