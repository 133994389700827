import React from 'react'
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export default function Header(props) {
    const [hidden, setHidden] = React.useState(true);
    const [open, setOpen] = React.useState(true);
    const [direction, setDirection] = React.useState('left');

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(!open);
    };
    const useStyles = makeStyles((theme) => ({
        root: {
            transform: 'translateZ(0px)',
            flexGrow: 1,
        },
        radioGroup: {
            margin: theme.spacing(10, 10),
        },
        speedDial: {
            position: 'fixed',
            zIndex: 9999,
            top: 30,
            right: 120,
            float: 'right',
            width: '100%',
        },
    }));
    const classes = useStyles();

    const actions = [
        // { route: '/services', name: 'Blog' },
        { route: '/contact', name: 'Contacto' },
        { route: '/about', name: 'Nosotros' },
        { route: '/projects', name: 'Proyectos' },
        { route: '/services', name: 'Servicios' },
    ];
    return (
        <div>
            <Link to="/">
                {
                    props.black ?
                        <img className="logo wow animate__animated animate__backInLeft" src="/img/logo_black.png" />
                        :
                        <img className="logo wow animate__animated animate__backInLeft" src="/img/logo_white.png" />

                }
            </Link>
            {
                !props.withOutMenu && (
                    <SpeedDial
                        ariaLabel="SpeedDial example"
                        className={classes.speedDial}
                        hidden={true}
                        icon={<SpeedDialIcon style={{ color: props.black ? 'black' : 'white' }} />}
                        // onClose={handleClose}
                        open={open}
                        direction={direction}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={<Link className="wow animate__animated animate__backInRight" style={{ color: props.black ? 'black' : 'white' }} to={action.route}>{action.name}</Link>}
                            />
                        ))}
                    </SpeedDial>
                )
            }


            <a target="_blank" href="https://web.whatsapp.com/send?phone=+524424730896" className="whats">
                <img className=" wow animate__animated animate__backInUp" src="/img/whats.png" />
            </a>
            {/* <MessengerCustomerChat
                pageId="106983631112819"
                appId="<APP_ID>"
                htmlRef="<REF_STRING>"
            /> */}
        </div>
    )
}
