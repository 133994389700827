import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import LocationIcon from '@material-ui/icons/LocationOn';
import TwitterIcon from '@material-ui/icons/Mail';
import YouTubeIcon from '@material-ui/icons/Phone';

export default function ContactIcons(props) {
    return (
        <div className={props.align}>
            <a target="_blank" href="https://goo.gl/maps/5V9e6exGarUXnckp9">
                <IconButton aria-label="Instagram">
                    <LocationIcon className={`iconSocialWhite`} />
                </IconButton></a>
            <a target="_blank" href="mailto:hola@4thinkdigital.com">
                <IconButton aria-label="Twitter">
                    <TwitterIcon className={`iconSocialWhite`} />
                </IconButton></a>
            <a target="_blank" href="tel:4428141264">
                <IconButton aria-label="Youtube">
                    <YouTubeIcon className={`iconSocialWhite`} />
                </IconButton>
            </a>
        </div>
    )
}
