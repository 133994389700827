import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaperServices from './PaperServices';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 440,
    width: 260,
    borderRadius: 25
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function CardService() {
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();

  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={spacing}>
          <Grid item>
            <PaperServices
              title="Marketing Digital"
              subtitle="Impulsamos el potencial de tu marca"
              buttonTitle="Sin miedo al éxito"
              image="/img/anim/mkt.jpg"
              link ="/services/mkt"
              anim = "url('/img/anim/mkt.gif')"
            />
          </Grid>
          <Grid item>
            <PaperServices
              title="Diseño & Arte Visual"
              subtitle="Ojos que no ven, corazón que no siente"
              buttonTitle="Métele más diseño"
              image="/img/anim/design.png"
              link ="/services/design"
              anim = "url('/img/anim/design.gif')"

            />
          </Grid>
          <Grid item>
            <PaperServices
              title="Plataformas digitales"
              subtitle="Más que una página bonita, un sitio web de impacto"
              buttonTitle="Tunegocio.com"
              image="/img/anim/digital.png"
              link ="/services/digital"
              anim = "url('/img/anim/digital.gif')"

            />
          </Grid>
          <Grid item>
            <PaperServices
              title="Nuevas Tecnologías"
              subtitle="Genera experiencias virtuales para tus clientes"
              buttonTitle="El futuro es hoy"
              image="/img/anim/tecnology.jpg"
              link ="/services/tecnology"
              anim = "url('/img/anim/tecnology.gif')"

            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}