import React from 'react';
import Container from '@material-ui/core/Container';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Grid from '@material-ui/core/Grid';
import CarouselMkt from '../../components/CarouselMkt';
import { Link } from 'react-router-dom';

export default function Marketing() {
    return (
        <div >
            <Header  />
            <section style={{backgroundColor:'black'}} >
                <CarouselMkt mkt />
            </section>
            <section className="bg-mkt-1 wow animate__animated animate__bounceInRight" >
                <Container >
                    <div className="flex-grow">
                        <Grid container spacing={3}>
                            <Grid item xs={6} >
                                <img className="pl-5 wow animate__animated animate__backInLeft" src="/img/services/mkt/fb.png" />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid item xs={12} className="pt-15 pl-10 wow animate__animated animate__backInRight">
                                    <h1 className="title-service ">Campañas</h1>
                                    <h1 className="subtitle-service">de publicidad</h1>
                                    <p className="text-service   pt-2">
                                    Aplicar un sinfín de técnicas que te permitirán generar impacto en tus productos o servicios, a través de la era digital llegando a la vida de millones de usuarios que están en constante  necesidad de probar nuevas experiencias de consumo.
                                    </p>
                                </Grid>
                            </Grid>
                          
                        </Grid>
                    </div>
                </Container>
            </section>
            <section className="bg-mkt-2 wow animate__animated animate__bounceInLeft" >
                <Container >
                    <div className="flex-grow">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Grid item xs={10} className="pt-15 pl-10">
                                    <h1 className="title-service wow animate__animated animate__fadeInUpBig">Social</h1>
                                    <h1 className="subtitle-service">media</h1>
                                    <p className="text-service pt-2 wow  animate__animated animate__backInRight">
                                        Demostrarte que tienes una tribu dispuesta a cautivarse y consumir tus productos y servicios a través de tu contenido de valor.
                                    </p>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} >
                                <img className="pl-5 wow animate__animated animate__backInRight" src="/img/services/mkt/insta.png" />
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>

            <section style={{backgroundColor:'#1C1C1C'}} className="wow animate__animated animate__bounceInLeft" >
                <Container >
                    <div className="flex-grow pt-5 pb-5">
                        <Grid container spacing={3}>
                            <Grid item xs={6} >
                                <img className="pl-5 wow animate__animated animate__bounceInLeft" src="/img/services/mkt/statics.jpg" />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid item xs={12} className="pt-10 pl-10">
                                    <h1 className="title-service text-white wow animate__animated animate__bounceInRight">Estrategías</h1>
                                    <h1 className="subtitle-service text-white">de marca</h1>
                                    <p className="text-service text-white   pt-2 wow animate__animated animate__bounceInRight">
                                    Llevar por el sendero del enamoramiento al <br/>Cliente - Consumidor, brindando la mejor experiencia que haga que nuestros objetivos sean logrados y los resultados de tu empresa o negocio sean exitosos.                                    </p>
                                </Grid>
                            </Grid>
                          
                        </Grid>
                    </div>
                </Container>
            </section>
            <section className="bg-about-2" >
                <Container >
                    <div className="flex-grow pt-15" style={{height:450}}>
                        <div class="subtitle-footer-btn">
                            <Link to="/contact">
                                <h1 className="title-general text-white text-left wow animate__animated animate__bounceInUp">Impactemos juntos</h1>

                                <span className="subtitle-footer text-white text-left">{'Contáctanos >'}</span>
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer bgBlack white  />
        </div>
    );
}