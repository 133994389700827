import React from 'react';
import Container from '@material-ui/core/Container';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Grid from '@material-ui/core/Grid';
import CarouselMkt from '../../components/CarouselMkt';
import { Link } from 'react-router-dom';

export default function Digital() {
    return (
        <div >
            <Header  />
            <section style={{backgroundColor:'black'}} >
                <CarouselMkt digital />
            </section>
            <section className="bg-digital-1 pt-15 pb-15 wow animate__animated animate__bounceInRight" >
                <Container  >
                    <div className="flex-grow">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Grid item xs={8} className="pt-5 ">
                                    <h1 className="title-service">E-commerce</h1>
                                    <h1 className="subtitle-service">y websites</h1>
                                    <p className="text-service   pt-2">
                                    Tu marca es única y debes mostrarla al mundo con todo su potencial. 
                                    <br />
                                    Nos encargamos de crear diseños y funciones a la medida para tu negocio.
                                    </p>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} >
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>
            <section className="bg-digital-2  wow animate__animated animate__bounceInLeft" >
                <Container >
                    <div className="flex-grow pb-5">
                        <Grid container spacing={3}>
                            <Grid item xs={6} >
                                <img width="700" src="/img/services/digital/app.png" />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid item xs={10} className="pt-15 pl-10">
                                    <h1 className="title-service text-white">Aplicaciones</h1>
                                    <h1 className="subtitle-service text-white">móviles</h1>
                                    <p className="text-service pt-2 text-white">
                                    Ofrece a tus clientes nuevas experiencias de compra, interacción o entretenimiento desde cualquier dispositivo.                                    </p>
                                </Grid>
                            </Grid>
                         
                        </Grid>
                    </div>
                </Container>
            </section>

            <section className="bg-digital-3 pt-15 pb-15 wow animate__animated animate__bounceInRight" >
                <Container  >
                    <div className="flex-grow">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Grid item xs={10} className="pt-5 pl-10 pb-15">
                                    <h1 className="title-service">Software </h1>
                                    <h1 className="subtitle-service">a la medida</h1>
                                    <p className="text-service   pt-2">
                                        Olvídate de pagar costosas 
                                        suscripciones. Desarrollamos un programa a la medida de tu negocio.
                                    </p>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} >
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>
            <section className="bg-digital-footer" >
                 <Container >
                    <div className="flex-grow pt-15" style={{height:450}}>
                        <div class="subtitle-footer-btn">
                            <Link to="/contact">
                                <h1 className="title-general text-white text-left wow animate__animated animate__bounceInUp">Desarrollemos juntos</h1>

                                <span className="subtitle-footer text-white text-left">{'Contáctanos >'}</span>
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer bgBlack white  />
        </div>
    );
}